import React from "react";
import { Col, Row } from "react-bootstrap";
import FAQ from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SIGNUP_URL } from "../../util/constants";

const KegtronProIntegration = () => {
  const pageExtra = (
    <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
      <Row>
        <Col className="justify-content-center text-center">
          <p class="lead">
            Ready to give the integration a try? Get your account running in
            minutes.
          </p>
          <a className="m-btn m-btn-theme2nd m-btn-radius" href={SIGNUP_URL}>
            Get Started
          </a>
        </Col>
      </Row>
    </section>
  );

  return (
    <Layout extra={pageExtra}>
      <SEO
        title={"Kegtron Pro TV menu with Taplist.io"}
        description={
          "Connect your Kegtron Pro account to Taplist.io to get an automatic, instantly-updating TV menu and web page."
        }
      />
      <section className="section" style={{ paddingTop: 30 }}>
        {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
        <Row>
          <Col className="justify-content-center text-center">
            <h1>Kegtron Pro Integration</h1>
            <p className="lead">
              Connect your Kegtron Pro account to Taplist.io to get an{" "}
              <b>automatic, instantly-updating TV menu and web page</b>.
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>What it does</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              When you provide your Kegtron Pro API Key to Taplist.io,
              Taplist.io will automatically and continuously synchronize your
              data from Kegtron.
            </p>
            <ul>
              <li>
                <b>Dynamic, level-sensitive keg artwork.</b> Taplist.io
                automatically generates keg illustrations with the SRM color and
                approximate remaining keg volume. Customers love seeing which
                kegs are running low!
              </li>
              <li>
                <b>Automatic keg &amp; tap synchronization (from Kegtron).</b>{" "}
                When you place a new beverage on tap in Kegtron, Taplist.io will
                pull the beverage data in and automatically update your TV and
                web menus.
              </li>
              <li>
                <b>Web &amp; embed support.</b> All of our other great features
                work when you use this integration, including our excellent and{" "}
                <a href="https://help.taplist.io/article/51-customize-embed-menu">
                  customizable embedded menu
                </a>
                , QR code support, and much more!
              </li>
            </ul>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>How it works</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              Taking advantage of this integration is really simple. Here's how:
            </p>
            <ol>
              <li>
                <b>Get an account.</b> You'll need a Taplist.io account on one
                of our paid plans.{" "}
                <a href="https://taplist.io/signup">Get started now</a>, it only
                takes a few minutes.
              </li>
              <li>
                <b>Configure the integration in the dashboard.</b> Visit the{" "}
                <a href="https://app.taplist.io/account/integrations/kegtron">
                  Kegtron Pro Integration configuration page
                </a>{" "}
                in the Taplist.io dashboard and follow the instructions there.
                You'll be asked to plug in a Kegtron Pro API Key.
              </li>
            </ol>
            <p>
              For additional steps and help, see the{" "}
              <a href="https://help.taplist.io/article/69-kegtron-pro">
                Kegtron Pro Integration Guide
              </a>{" "}
              in our Help Center.
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>FAQ</h2>
          </Col>
          <Col md={8}>
            <FAQ
              faqItems={[
                {
                  question: "Does this integration cost extra?",
                  answer:
                    "No. Taplist.io does not charge any additional fee for the Kegtron Pro integration.",
                },
                {
                  question: "What Taplist.io plans support this integration?",
                  answer: (
                    <>
                      This integration is available to all <b>Pro</b> and{" "}
                      <b>Hobby</b> subscribers.
                    </>
                  ),
                },
                {
                  question: "Does this work with classic (bluetooth) Kegtron devices?",
                  answer: (
                    <>
                      Unfortunately, no. Our Kegtron integration requires a Kegtron Pro
                      system and is not compatible with bluetooth-only Kegtron devices, 
                      as these devices do not have the requisite cloud connectivity.
                    </>
                  ),
                },
                {
                  question: "Does Taplist.io read temperature data?",
                  answer:
                    "Yes, Kegtron-provided temperature data is read and can optionally be shown on some menu themes.",
                },
              ]}
            />
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default KegtronProIntegration;
